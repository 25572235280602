<template>
  <main class="ma-4 elevation-2" style="background-color: white">
    <v-tabs slider-color="main" riple show-arrows>
      <v-tab class="cursor-pointer">
        <div class="main--text">{{ translations.localUnitList }}</div>
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="localUnits"
      :items-per-page="pageSize"
      :updateOption="{
        page: page,
        itemsPerPage: pageSize
      }"
      dense
    >
      <template v-slot:footer>
        <div class="d-flex justify-end align-center pa-2">
          <span class="mr-2">{{ page === 1 ? initialNumber : (page - 1) * 10 + 1 }} - {{ (page - 1) * 10 + localUnits.length }} di {{ totalLocalUnits }}</span>

          <v-btn @click="onPreviousTableChange" class="mr-2" icon :disabled="page === 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn @click="onNextTableChange" class="" icon :disabled="page === totalPages"> <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" height="32px" depressed @click="openSaveNewLocalUnitDialog()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            {{ translations.newLocalUnit }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.operations`]="{ item }">
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <v-icon small class="mr-2" @click="openEditLocalUnitDialog(item)">
              mdi-pencil
            </v-icon>
            <v-icon small color="error lighten-1" class="mr-2" @click="deleteLocalUnit(item)">
              mdi-delete
            </v-icon>
          </div>
        </div>
      </template>
    </v-data-table>

    <!-- new LocalUnit dialog -->
    <v-dialog v-model="dialog" max-width="400px" @keydown.enter="disabled ? null : saveLocalUnit(localUnit)">
      <v-card class="pa-2">
        <v-card-title>
          <v-row class="ma-2" justify="center">
            <h3 class="main--text">
              {{ dialogTitle }}
            </h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-col class="pa-0">
            <b>{{ translations.name }}</b>
            <v-text-field ref="name" v-model="localUnit.name" outlined hide-details dense></v-text-field>
          </v-col>
          <v-col class="pa-0 mt-4">
            <b>{{ translations.description }}</b>
            <v-text-field v-model="localUnit.description" outlined hide-details dense></v-text-field>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" depressed :disabled="disabled" @click="saveLocalUnit(localUnit)">
            {{ translations.confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-0" align="center" justify="center">
      <v-col class="pa-2" cols="auto">
        <v-alert v-model="showErrorAlert" dense text type="error" dismissible>{{ translations.cannotDeleteLocalUnit }}</v-alert>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import { translations } from "@/utils/common";
import { mdiPlus } from "@mdi/js";

// queries
import localUnitsQuery from "@/graphql/LocalUnits.gql";
import saveLocalUnit from "@/graphql/SaveLocalUnit.gql";
import deleteLocalUnit from "@/graphql/DeleteLocalUnit.gql";

export default {
  apollo: {
    localUnits: {
      query: localUnitsQuery,
      variables() {
        return {
          page: this.page,
          pageSize: this.pageSize
        };
      },
      fetchPolicy: "network-only",
      result({ data }) {
        const { content, hasNext, totalPages, totalElements } = data.localUnits;
        this.localUnits = content;
        this.totalLocalUnits = totalElements;
        this.totalPages = totalPages;
        this.hasNext = hasNext;
      }
    }
  },

  data: () => ({
    headers: [
      { text: translations.name, value: "name" },
      { text: translations.description, value: "description" },
      { text: translations.operation, value: "operations" }
    ],
    localUnit: {
      name: "",
      description: ""
    },
    localUnits: [],
    totalLocalUnits: null,
    totalPages: null,
    hasNext: true,
    page: 1,
    pageSize: 10,
    dialog: false,
    dialogTitle: "",
    showErrorAlert: false
  }),

  methods: {
    openSaveNewLocalUnitDialog() {
      this.dialog = true;
      this.localUnit = {
        name: "",
        description: ""
      };
      this.dialogTitle = this.translations.newLocalUnit;

      // focus on first input field
      setTimeout(() => {
        this.$refs.name.focus();
      }, 200);
    },

    openEditLocalUnitDialog(localUnit) {
      this.dialog = true;

      this.localUnit = {
        id: localUnit.id,
        name: localUnit.name,
        description: localUnit.description
      };

      this.dialogTitle = this.translations.edit + " " + localUnit.name;
    },

    async saveLocalUnit(localUnit) {
      await this.$apollo
        .mutate({
          mutation: saveLocalUnit,
          variables: { localUnitDTO: localUnit }
        })
        .then(res => {
          this.dialog = false;
          const newLocalUnit = res.data.saveLocalUnit;
          const index = this.localUnits.findIndex(localUnit => localUnit.id == newLocalUnit.id);
          if (index == -1) this.localUnits.push(newLocalUnit);
          else {
            this.localUnits[index] = newLocalUnit;
            this.localUnits.splice(index, 1, newLocalUnit);
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      this.incrementPages();
    },

    incrementPages() {
      this.totalLocalUnits = this.localUnits.length;
      this.hasNext = true;
      this.totalPages = Math.ceil(this.localUnits.length / this.pageSize);
    },

    async deleteLocalUnit(localUnit) {
      await this.$apollo
        .mutate({
          mutation: deleteLocalUnit,
          variables: { localUnitId: localUnit.id }
        })
        .then(() => {
          const index = this.localUnits.indexOf(localUnit);
          this.localUnits.splice(index, 1);
          this.totalLocalUnits -= 1;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.showErrorAlert = true;
          setTimeout(() => (this.showErrorAlert = false), 6000);
        });
    },

    onNextTableChange() {
      if (!this.hasNext) return;
      this.page++;
    },

    onPreviousTableChange() {
      if (this.page === 1) return;
      this.page--;
    }
  },

  computed: {
    translations: () => translations,

    icons: () => ({
      mdiPlus
    }),

    initialNumber() {
      if (this.localUnits.length === 0) return 0;

      return 1;
    },

    disabled() {
      return !this.localUnit.name || !this.localUnit.description;
    }
  }
};
</script>
